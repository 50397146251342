

















































import { Component, Vue } from "vue-property-decorator";
import { IEventCreate } from "@/interfaces";
import { dispatchCreateEvent, dispatchGetProject } from "@/store/main/actions";
import { readProject } from '@/store/main/getters';

@Component
export default class Create extends Vue {
  public loading = true;
  public valid = false;

  public event: IEventCreate = {
    name: "",
    type: "",
    project_id: 0,
  };

  public reset() {
    this.event = <IEventCreate>{};
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  get project(){
    return readProject(this.$store);
  }

  public async mounted() {   
    await dispatchGetProject(this.$store, Number(this.$router.currentRoute.params.id));
    this.loading = false;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.event.project_id = Number(this.$router.currentRoute.params.id);
      await dispatchCreateEvent(this.$store, this.event);
      this.$router.back();
    }
  }
}
